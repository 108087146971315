<template>
    <div class="footter">
        <div class="container" style="height: 100%">
            <div class="data">
                <span class="copyright">CopyRight © 2022 Feymen</span>      
                <div class="center">
                    <div class="iconsContainer">
                        <a class="ic" :href="getEnv('FACEBOOK')">
                            <font-awesome-icon icon="fa-brands fa-facebook-square"></font-awesome-icon>
                        </a>
                        <a class="ic" :href="getEnv('INSTAGRAM')">
                            <font-awesome-icon icon="fa-brands fa-instagram"></font-awesome-icon>
                        </a>
                    </div>
                    <div class="iconText">
                        <font-awesome-icon class="ic" icon="fa-solid fa-phone"></font-awesome-icon>
                        <span>{{ getEnv('PHONE') }}</span>
                    </div>
                    <div class="iconText">
                        <font-awesome-icon class="ic" icon="fa-solid fa-house"></font-awesome-icon>
                        <span>{{ getEnv('ADDRESS') }}</span>
                    </div>
                </div>
                <div class="logo">
                    <div class="logoContainer">
                        <img src="/allmake.svg">
                        <span>Stworzone przez</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getEnv } from '@/utils.js'

export default {
    methods: {
        getEnv
    }
}
</script>

<style lang="scss" scoped>
@import "~bulma/sass/utilities/mixins";

.footter {
    @include desktop {
        height: 75px;
    }

    @include touch {
        padding-bottom: 25px;
        padding-top: 25px;
    }
    background-color: #111111;
    
    .data {
        height: 100%;
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        @include touch {
            flex-direction: column;
            gap: 20px;
        }

        .copyright {
            color: #fff;
            font-weight: 500;
            font-size: 1rem;
            flex: 1;

            @include touch {
                text-align: center;

                order: 2;
            }
        }

        .center {
            display: flex;
            align-items: center;
            gap: 40px;

            @include touch {
                flex-direction: column;
                gap: 10px;

                order: 1
            }
        }

        .logo {
            flex: 1;
            display: inline-flex;
            justify-content: flex-end;
            
            img {
                height: 30px;

                
                @include touch {
                    height: 25px;
                }
            }

            .logoContainer {
                display: flex;
                align-items: center;
                position: relative;
            }

            span {
                position: absolute;
                left: 18%;
                right: 0;
                text-align: center;
                font-size: 0.8rem;

                top: 85%;

                color: #fff;
            }

            @include touch {
                order: 3;
            }
        }
    }
}

.iconsContainer {
    display: flex;
    gap: 10px;
    align-items: center;

    .ic {
        color: var(--primary);
        font-size: 1.75rem;

        transition: color .5s;

        &:hover {
            color: #fff;
        }
    }
}

.iconText {
    display: flex;
    align-items: center;
    gap: 10px;

    span {
        font-size: 1.25rem;
        color: #fff;
    }

    .ic {
        color: var(--primary);
        font-size: 1.8rem;
    }
}
</style>