<template>
    <div class="container" style="padding-top: 4.5rem">
        <div class="nav">
            <div class="logo" @click="$router.push('/')">
                <img src="/images/logoSmall.svg">
            </div>
            <div class="links">
                <div :class="['longue', {'active': active}]">
                    <ul class="links-list">
                        <li>
                            <router-link to="/">Strona głowna</router-link>
                        </li>
                        <li>
                            <router-link to="/o-nas">O nas</router-link>
                        </li>
                        <li>
                            <router-link to="/cennik">Cennik</router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="right">
                <router-link to="/kontakt" class="contactBtn">Kontakt</router-link>
            </div>
            <div class="lines" @click="active = !active">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            active: false
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~bulma/sass/utilities/mixins";

.nav {
    display: flex;
    align-items: center;

    @include touch {
        margin: 0 32px;
    }
}

.links {
    @include touch {
        // visibility: hidden;
        // display: none;

        position: absolute;
    }
}

.links-list {
    display: flex;
    gap: 150px;
    
    a {
        font-weight: 500;
        color:#fff;

        transition: color .5s;

        &:hover {
            color: var(--primary);
        }
    }

    @include touch {
        flex-direction: column;
        gap: 10px;
        justify-content: center;

        text-align: center;

        a {
            font-size: 1.3rem;
        }

        margin-bottom: 20px;
    }
}

.right {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;

    @include desktop {
        flex: 1;
    }
    
    @include touch {
        order: 2;
    }
}

.contactBtn {
    font-size: 1.1rem;
    background-color: var(--primary);
    padding: 6px 35px;
    border-radius: 24px;
    color: #fff;
    z-index: 3;

    @include touch {
        // visibility: hidden;
        // display: none;
        margin-right: 16px;
    }
}

.logo {
    flex: 1;
    
    img {
        height: 35px;
    }

    position: relative;
    z-index: 3;
}

.lines {
    visibility: hidden;

    @include touch {
        order: 3;
        visibility: visible;
        display: flex;

        gap: 8px;
        flex-direction: column;

        .line {
            width: 35px;
            height: 5px;
            background-color: #fff;
        }
    }

    position: relative;
    z-index: 3;
}

.longue {
    @include touch {
        position: fixed;
        
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        height: 300px;
        background-color: var(--dark);
        border-radius: 0 0 32px 32px;

        display: flex;
        align-items: flex-end;
        justify-content: center;

        gap: 20px;

        transform: translateY(-100%);

        transition: transform .5s, z-index .5s step-end;

        z-index: -2;

        &.active {
            z-index: 2;
            transform: translateY(0);

            transition: transform .5s, z-index .5s step-start;
        }
    }
}
</style>